import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <h1 style={{ fontSize: `300%`, fontFamily: `var(--font-glidge)` }}>404: Not Found</h1>
    <p>お探しのページは見つかりませんでした。</p>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
